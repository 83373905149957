.section {
  height: 839px;
  background: #F8F8F8;

  :global .wrap {
    height: 100%;
  }
}

.main {
  padding: 201px 0 0;
}

.title {
  font-size: 46px;
  font-family: PingFang SC;
  font-weight: 600;
  line-height: 64px;
  color: #333333;
  letter-spacing: 6px;
}

.desc {
  padding-top: 20px;
  font-size: 16px;
  font-family: PingFang SC;
  font-weight: 400;
  line-height: 28px;
  color: #666666;
}

.sepecial {
  padding-top: 91px;

  &__title {
    display: flex;
    align-items: center;
    font-size: 24px;
    font-family: PingFang SC;
    font-weight: bold;
    line-height: 33px;
    color: #49ae67;
  }

  &__img {
    width: 28px;
    height: 32px;
    display: block;
    margin-right: 11px;
  }

  &__desc {
    padding-top: 22px;
    font-size: 16px;
    font-family: PingFang SC;
    font-weight: 400;
    line-height: 32px;
    color: #666666;

    :global mark {
      color: #7794EA;
      background: none;
    }
  }

  & + & {
    padding-top: 62px;

    .sepecial__title {
      color: #446BDD;
    }

    .sepecial__img {
      width: 18px;
      height: 32px;
      margin-left: 8px;
      margin-right: 12px;
    }
  }
}

.img {
  height: 779px;
  position: absolute;
  top: 30px;
  left: 510px;
}
