body {
  max-width: 100vw;
  overflow-x: hidden;
}

/* 溢出省略号 */
.text_ellipsis {
  overflow: hidden;
  display: block;
  text-overflow: ellipsis;
  white-space: nowrap;
}
