@import '@scss';

.home {
  width: px(1140);
  padding: px(40) 0;
  margin-left: px(59);
}

.main_box {
  display: flex;
  min-width: 768px;
}

.h2 {
  margin-bottom: px(30);
  font-size: px(20);
  font-weight: 500;
}

.main_content {
  height: calc(100vh - #{px(415)});
  overflow: scroll;
}
.main_content::-webkit-scrollbar { width: 0 !important }
.main_content { -ms-overflow-style: none; }
.main_content { overflow: -moz-scrollbars-none; }


.textarea {
  padding: px(28) px(40);
  width: 100%;
  height: px(140);
  line-height: px(28);
  color: #363636;
  border: px(1) solid rgba(102, 102, 102, 0.5);
  box-sizing: border-box;
  border-radius: px(4);
}

.form_box {
  padding: px(18) 0 px(14);
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.form_aside {
  font-size: px(16);
  color: #999999;
}

.form_aside_num {
  font-weight: bolder;
}

.btn {
  height: px(40);
  padding: 0 px(20);
  background: #7794ea;
  border-radius: px(4);
  font-size: px(16);
  font-weight: 500;
  color: #fff;
  display: flex;
  align-items: center;
}

.save_img {
  margin-right: px(10);
  width: px(17);
  height: px(17.54);
}

.li {
  border: px(1) solid rgba(102, 102, 102, 0.1);
  box-shadow: px(0) px(3) px(16) px(0) #e9e9e9;
  border-radius: px(4);
  padding: px(30) px(40);
  margin-bottom: px(30);
  position: relative;
  background: #fff;

  &:hover .aside{
    display: block;
  }
}

.time {
  font-size: px(16);
  font-family: PingFang;
  font-weight: 400;
  color: #999999;
}

.text {
  padding-top: px(18);
  font-size: px(18);
  color: #363636;
  line-height: px(28);
  text-align: justify;
  white-space: pre-wrap;

  // overflow: hidden;
  // text-overflow: ellipsis;
  // display: -webkit-box;
  // -webkit-line-clamp: 3;
  // -webkit-box-orient: vertical;
  // white-space: pre-wrap;
}

.aside {
  display: none;
  background: rgba(0, 0, 0, 0.32);
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  cursor: pointer;

  &_div {
    display: flex;
    align-items: center;

    font-size: px(18);
    font-weight: bold;
    color: #FFFFFF;

    position: absolute;
    top: px(30);
    right: px(30);
  }

  &_img {
    width: px(18);
    height: px(18);
    display: block;
    margin-right: px(10);
  }
}

.result {
  padding-top: px(150);
}

.empty_img {
  width: px(141.5);
  display: block;
  margin: px(156) auto 0;
}