.section {
  height: 1039px;
  background: #f8f8f8;

  :global .wrap {
    height: 100%;
  }
}

.main {
  padding: 120px 0 0;
}

.title {
  font-size: 46px;
  font-family: PingFang SC;
  font-weight: 600;
  line-height: 64px;
  color: #333333;
  letter-spacing: 6px;
}

.desc {
  padding-top: 20px;
  font-size: 16px;
  font-family: PingFang SC;
  font-weight: 400;
  line-height: 28px;
  color: #666666;
}

.img {
  width: 1360px;
  height: 810px;
  margin: auto;
  position: absolute;
  left: -70px;
  bottom: 0;
}

.light {
  width: 122px;
  height: 188px;
  display: block;
  position: absolute;
  top: 109px;
  right: 17px;
}
