@import '@scss';

.main {
  transform: translateY(25vh);
  text-align: center;
}

.img {
  width: px(228);
}

.text {
  padding-top: px(56);
  font-size: px(20);
  font-weight: 400;
  color: #333333;
}

.result {
  transform: translateY(25vh);
}