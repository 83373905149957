@import "@scss";

.header {
  position: sticky;
  top: 0;
  z-index: 666;
  background: #fff;
  border-bottom: 1px solid #e4e4e4;
}

.wrap {
  height: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header_home {
  background: #fafafa;
  box-shadow: 0 3px 10px 0 #efefef;
}

.logo {
  font-size: 16px;
  color: #333;
  font-weight: 600;
  display: flex;
  align-items: center;
  cursor: pointer;

  &_img {
    width: 44px;
  }
}

.nav {
  display: flex;
  align-items: center;
  font-weight: 500;
  color: #666;
  font-size: 16px;

  &_login {
    margin-left: 80px;
    background: #333;
    color: #fff;
    border-radius: 4px;
    width: 143px;
    height: 40px;
    line-height: 40px;
    font-size: 16px;
    text-align: center;
  }

  &_popover {
    cursor: pointer;
    position: relative;
    margin-left: 80px;
  }

  &_img {
    width: 210px;
    height: 210px;
    display: block;
  }

  div {
    cursor: pointer;
  }
}
