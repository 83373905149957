.footer {
  height: 738px;
  background: #fff;
  text-align: center;
}

.logo {
  margin: 120px auto 20px;
  width: 120px;
  height: 120px;
  display: block;
}

.title {
  font-size: 46px;
  font-family: PingFang SC;
  font-weight: 600;
  line-height: 65px;
  letter-spacing: 6px;
  color: #333333;
}

.desc {
  padding-top: 14px;
  font-size: 16px;
  font-family: PingFang SC;
  font-weight: 400;
  line-height: 32px;
  color: #666666;
}

.btn {
  margin-top: 60px;
  width: 206px;
  height: 52px;
  background: #333333;
  border-radius: 2px;
  font-size: 17px;
  font-family: PingFang SC;
  font-weight: 600;
  color: #ffffff;

  &_img {
    width: 210px;
    height: 210px;
  }
}

.info {
  padding-top: 120px;
}

.copyRight {
  font-size: 16px;
  font-family: PingFang SC;
  font-weight: 400;
  line-height: 50px;
  color: #999999;
}

.bean {
  margin-top: 17px;
  font-size: 16px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #999999;
}
