@import "@scss";

.section {
  height: 624px;
  background: #f8f8f8;
}

.main {
  padding: 120px 0 0;
}

.title {
  font-size: 52px;
  font-family: PingFang SC;
  font-weight: 600;
  line-height: 64px;
  color: #333333;
  letter-spacing: 6px;
}

.desc {
  padding-top: 20px;
  font-size: 16px;
  font-family: PingFang SC;
  font-weight: 400;
  line-height: 28px;
  color: #666666;
}

.btn {
  width: 204px;
  height: 52px;
  background: #333333;
  border-radius: 2px;
  margin-top: 81px;
  font-size: 16px;
  font-family: PingFang SC;
  font-weight: 600;
  line-height: 22px;
  color: #ffffff;

  &_img {
    width: 210px;
    height: 210px;
  }
}

.pai {
  margin-top: 13px;
  display: flex;
  align-items: center;

  &_img {
    width: 23px;
    height: 30px;
    display: block;
    transform: translateY(-2px);
  }

  &_txt {
    font-size: 16px;
    font-family: PingFang SC;
    font-weight: 500;
    color: #cfaf7e;
  }
}

.banner {
  width: 601px;
  height: 389px;
  display: block;
  position: absolute;
  top: 104px;
  right: 0;
}
