@import '@scss';

.sidebar {
  width: px(341);
  box-shadow: px(0) px(3) px(40) px(0) #E9E9E9;
  background: #fff;
}

.dt {
  font-size: px(20);
  font-family: PingFang;
  font-weight: bold;
  color: #333333;
  padding: px(30);
  border-bottom: px(1) solid #EEEEEE;
  margin-bottom: px(15);

  &_box {
    max-height: calc(100vh - 64px - 69px - 13px);
    overflow: scroll;
  }
}

.dd {
  padding: px(15) px(20) px(15) px(60);
  font-size: px(18);
  font-family: PingFang;
  color: #363636;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.select {
  background: #E4EAFB;
}

.folderImg {
  width: px(20);
  height: px(20);
  margin-right: px(18);
}
