.section {
  height: 885px;
  background: #fff;
}

.main {
  padding: 120px 0 0;
  position: relative;
  z-index: 1;
}

.title {
  font-size: 46px;
  font-family: PingFang SC;
  font-weight: 600;
  line-height: 64px;
  color: #333333;
  letter-spacing: 6px;
}

.desc {
  padding-top: 20px;
  font-size: 16px;
  font-family: PingFang SC;
  font-weight: 400;
  line-height: 28px;
  color: #666666;
}

.specials {
  padding-top: 130px;

  &_item {
    width: 438px;
    padding-left: 24px;
    display: flex;
    align-items: center;
    cursor: pointer;
    transition: all 0.2s;
    border-radius: 8px;
    overflow: hidden;

    &__img {
      width: 32px;
      height: 32px;
    }

    &__right {
      padding-left: 20px;
    }

    &__title {
      font-size: 20px;
      font-family: PingFang SC;
      font-weight: 400;
      line-height: 28px;
      color: #333333;
      display: flex;
      align-items: center;
    }

    &__tag {
      margin-left: 8px;
      height: 22px;
      padding: 0 9px;
      background: rgba(248, 188, 43, 0.2);
      border-radius: 18px;
      text-align: center;
      font-size: 11px;
      font-family: PingFang SC;
      font-weight: 600;
      color: #eb5e00;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &__tag2 {
      background: rgba(42, 198, 146, 0.16);
      color: #0ab57a;
    }

    &__txt {
      padding-top: 8px;
      font-size: 16px;
      font-family: PingFang SC;
      font-weight: 400;
      line-height: 20px;
      color: #7b7d87;
      display: none;
    }

    &__active {
      background: rgba(119, 148, 234, 0.08);
      padding: 26px 24px;
      padding-right: 0;

      .specials_item__title {
        color: #446bdd;
        font-weight: bold;
      }

      .specials_item__txt {
        display: block;
      }
    }

    & + & {
      margin-top: 32px;
    }
  }
}

.img {
  width: 802px;
  height: 885px;
  display: block;
  position: absolute;
  right: -100px;
  top: 0;
}
